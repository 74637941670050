
.first-section {
    flex: 55%;
    display: flex;
    flex-direction: column;
}

.second-section {
    flex: 0%;
    background-color: #131418;

}

.navigation {
    background-color: #080808;
    border-bottom: 1px solid rgb(42, 46, 56) ;
    margin-top: 0px;
    color: #fff;
    padding: 1rem 0rem;
    width: 95%;
}

.navigation-container-stream-deshboard {
    display: flex;
    align-items: center;
    height: 4rem;
    justify-content: space-between;
    padding: 0rem 1rem;
}

.navigation-container-stream-deshboard-infouser {
    display: flex;
    padding: 10px 25px;
    border-right: 1px solid #2a2e38;
    font-weight: bolder;
    font-family: inherit;
    align-items: center;
}

.navigation-container-stream-deshboard-infouser>div {
    padding: 0px 10px;
}

.container-relatives-deshboard {
    display: flex;
}

.relative button {
    background: #131418;
    border: 1px solid #ff53da;
    margin: 0px 7px;
}

.border-sec {
    display: flex;
    height: 20px;
    flex-shrink: 0;
    flex-direction: row;
    align-items: center;
    gap: 4px;
    border-left: 1px solid #232628;
    background-color: #171c1e;
    padding: 0 6px;
}

.flex {
    display: flex;
}

.h-20 {
    height: 20px;
}

.flex-row {
    flex-direction: row;
}

.items-center {
    align-items: center;
}

.gap-4 {
    gap: 4px;
}

.border-l {
    border-left: 1px solid #232628;
}



.px-6 {
    padding-left: 6px;
    padding-right: 6px;
}

.select-none {
    user-select: none;
}

.rounded {
    border-radius: 0.25rem;
}

.p-2 {
    padding: 0.5rem;
}

.flex-col {
    flex-direction: column;
}

.text-sm {
    font-size: 0.875rem;
}

.font-bold {
    font-weight: 700;
}



.divider {
    border-top: 1px solid rgba(255, 255, 255, 0.2);
}

.relative {
    position: relative;
}

.base-icon-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: none;
    background-color: transparent;
    cursor: pointer;
}

.base-icon {
    display: flex;
    width: 20px;
    height: 20px;
}

.base-icon-button .icon {
    width: 20px;
    height: 20px;
}

.content {
    display: flex;
    justify-content: space-between; 

}

.part-one-chat {
    overflow: hidden;
    display: flex;
    justify-content: center;
}

.part-two {
    width: 60%;
}

.column {
    display: flex;
    flex-direction: column;
    /* width: 100%; */

    max-width: 83rem;
    justify-content: flex-start;
    gap: 20%
}

.column>div {
    margin: 5px;
    border-radius: 5px;
}

.Información-sesión-p1 {
    display: flex;
    flex-direction: column;
    padding: 10px;
    color: #ffff;
    justify-content: space-between;
    font-weight: bolder;
}
.Información-sesión{
   background-color:  rgb(19, 20, 24);
}
.vista-previa-stream-p1 {
    display: flex;
    align-items: center;
    padding: 10px;
    color: #ffff;
}

.base-card {
    padding: 0 !important;
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    background-color: rgb(25 27 31 / var(--tw-bg-opacity));
    color: rgb(255 255 255 / var(--tw-text-opacity));
}

.p-0 {
    padding: 0 !important;
}

.flex {
    display: flex;
}


.w-full {
    width: 100%;
}

.flex-row {
    flex-direction: row;
}

.items-center {
    align-items: center;
}

.justify-between {
    justify-content: space-between;
}


.rounded-t {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}



.base-icon {
    display: inline-block;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.text-white {
    color: #fff;
}

.max-w-full {
    max-width: 100%;
}

.shrink {
    flex-shrink: 1;
}

.truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.font-bold {
    font-weight: 700;
}

.ml-auto {
    margin-left: auto;
}

.shrink-0 {
    flex-shrink: 0;
}

.size-sm {
    width: 16px;
    height: 16px;
}

.base-icon-button {
    border: none;
    background: none;
    cursor: pointer;
}

.h-full {
    height: 100%;
}

.overflow-y-hidden {
    overflow-y: hidden;
}

.rounded-b {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

.session-info {
    padding: 0.375rem 0.8125rem;
}

.w-fit {
    width: fit-content;
}

.grow-0 {
    flex-grow: 0;
}





.py-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
}


.uppercase {
    text-transform: uppercase;
}


.data {
    font-weight: 700;
}

.session-info {
    display: flex;
}

.stats-container {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    font-family: inter;
    color: #929ea6;
    padding: 10px;
   
    width: 25%;
    justify-content: space-around;
}
/* Aplica el borde derecho a todos excepto el último */
.stats-container:not(:last-child) {
    border-right: 1px solid #232628;
  }
.label {
    /* padding: 10px 0px; */
}

.Broadcast-preview-dashboard {
    display: flex;
    /* justify-content: center; */
    /* height: 100% !important; */
    flex-direction: column;
    background-color: #131418;
    padding: 15px;
    border-radius: 5px;
}

.sin-conexion-s3 {
    padding: 6px;
    color: #fff;
    position: relative;
    font-weight: bolder;
}

.right-panel {
    display: flex;
    flex-direction: column;
    background-color: #171C1E;
    flex-grow: 1;
}

.right-panel__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0.75rem;
    background-color: #131418;
}

.right-panel__header span {
    font-size: 1.125rem;
    font-weight: bold;
    color: #FFFFFF;
}

.right-panel__content {
    color: #ffff;
    font-weight: bold;
    background: #131418;
}

.divider {
    border-top: 1px solid #3C454D;
    margin: 0.5rem 0;
}

.flex-column {
    display: flex;
    flex-direction: column;
}

.channel-actions-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 0;
}

.channel-actions-item span {
    font-size: 1rem;
    color: #FFFFFF;
}

.base-toggle {
    width: 36px;
    height: 20px;
    background-color: #3C454D;
    border-radius: 10px;
    position: relative;
    cursor: pointer;
}

.base-toggle-indicator {
    width: 16px;
    height: 16px;
    background-color: #1F2937;
    border-radius: 50%;
    position: absolute;
    top: 2px;
    left: 2px;
    transition: transform 0.5s ease;
}

.toggle-size-sm {
    width: 36px;
    height: 20px;
}

.channel-actions-link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 0;
    text-decoration: none;
    color: #FFFFFF;
}

.channel-actions-link svg {
    width: 16px;
    height: 16px;
}

.shrinkS2 {
    padding: 1rem;
}

.creator-actions-item {
    display: flex;
    padding: 5px 10px;
    border-radius: 5px;
    animation: 200ms all;

}

.creator-actions-item:hover {
    background: #313538;
}

.creator-actions-item>span {
    padding: 10px;
}

.menuModChatSlowMode {
    display: flex;
    padding: 16px;
}


.SecondModChatSlowModeInput {
    display: flex;
    z-index: 100000000;
}

.base-card {
    padding: 0;
}

.flex {
    display: flex;
}

.flex-col {
    flex-direction: column;
}

.h-full {
    height: 100%;
}

.max-h-full {
    max-height: 100%;
}

.overflow-hidden {
    overflow: hidden;
}

.rounded-t {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
}



.w-full {
    width: 100%;
}

.flex-row {
    flex-direction: row;
}

.items-center {
    align-items: center;
}

.justify-between {
    justify-content: space-between;
}


.base-card-act {
    /* width: 100%; */
    /* height: 100%  !important; */
    /* padding: 12px; */
}

.Feeddeactividades {
    width: 13rem;
    padding: 6px 13px;
    display: flex;
    align-items: center;
    color: #fff;

}

.mapFeedAct {
    padding: 6px 13px;
}

.truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.text-base {
    font-size: 1rem;
}

.font-bold {
    font-weight: bold;
}

.text-white {
    color: #fff;
}

.ml-auto {
    margin-left: auto;
}

.shrink {
    flex-shrink: 1;
}

.base-icon {
    width: 20px;
    height: 20px;
}

.variant-text {
    font-family: inherit;
    font-size: 1rem;
}

.size-sm {
    font-size: 0.875rem;
}

.base-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.button-content {
    display: flex;
    align-items: center;
}

.inner-label {
    margin-left: 0.5rem;
}

.relative {
    position: relative;
}

.base-icon-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.activity-feed-item {
    display: flex;
    align-items: center;
    padding: 10px 0px;
}

.soft-hidden {
    visibility: hidden;
}

.font-normal {
    font-weight: normal;
}

.mapFeedAct {
    max-height: 50vh !important;
    overflow-y: overlay;
    color: #fff;
}

.activity-feed-item__info {
    padding-left: 4px;
    font-family: inter;
    font-weight: 600;
    display: flex;
    width: 100%;
    gap: 10px;
    align-items: center;
}

.activity-feed-item__info_action {}


/* Medium (tabletas y teléfonos grandes) */
@media (max-width: 768px) {
    .content{
        display: flex;
        flex-direction: column;
    }
    .part-two{
        border: none;
        width: 100%;
    }
    .part-two > .column{
        width: 100%;
        max-width: 100%;
    }
    .navigation{
        padding: 3rem 0rem;
        width: 100% !important;
    }
  }


  

  .draggable-section {
    transition: all 0.2s ease;
  }