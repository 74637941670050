.navTopHome {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 12px 1.5rem; /* Reduce padding para más espacio */
    position: sticky;
    top: 0;
    z-index: 9999;
    background-color: rgb(8, 8, 8);
    width: 100%;
    box-shadow: 6px 2px 20px 0px #e172ff0f;
   
}

.navTop {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 1150px; /* Ajustado para que no se corte en 1280px */
    margin: 0 auto;
    padding: 0 1rem; /* Reduce el padding lateral */
    flex-wrap: nowrap; /* Mantiene en una fila */
}

/* 📌 Ajuste especial para 1280x720 */
@media (max-width: 1280px) {
    .navTop {
        max-width: 1100px; /* Reduce el ancho para evitar desbordes */
        padding: 0 0.8rem;
        margin: 0;
    }

    .submenu-layout{
        right: 20% !important;
    }
}

/* 📌 Para pantallas más pequeñas */
@media (max-width: 1024px) {
    .navTop {
        max-width: 100%;
        padding: 0 0.5rem;
        flex-wrap: wrap; /* Permite que los elementos bajen si es necesario */
        justify-content: center;
    }
}

/* Para pantallas pequeñas */
@media (max-width: 768px) {
    .navTop {
        flex-wrap: wrap; /* Evita desbordes en pantallas pequeñas */
        justify-content: center;
        padding: 0.5rem;
    }
}

@media (min-width: 1200px) and (max-width: 1599px) {
    /* Estilos para desktops estándar */
    .navTop{
        width: 85% !important;
    }
  }

.menu-aside-option {
    width: 100%;
    transition: all .2s ease-in-out;
    position: relative;
}

.openNotificationopenMessage {
    width: 25%;
    border: 1px solid rgb(42, 46, 56);
    height: 100vh;
    background-color: #080808;
    position: fixed;
    z-index: 100000;
    top: 0px;
    transition: left 0.5s ease
}



.css-hip9hq-MuiPaper-root-MuiAppBar-root {
    background-color: transparent !important;
}

.css-ypiqx9-MuiDialogContent-root {
    padding: 0px !important;
}

/* menu.css */
.mobile-menu {
    position: fixed;
    border-top: 2px solid #2a2e38;
    /* Ajuste del borde superior */
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #121418;
    color: white;
    display: flex;
    justify-content: space-around;
    align-items: center;
    z-index: 100000;
    /* Asegura que el menú esté por encima de otros elementos */
}

.mobile-menu a {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    flex: 1;
    padding: 18px 0;
    text-decoration: none;
    color: inherit;
    font-size: 18px;
    position: relative;
}

.mobile-menu a:not(:last-child) {
    border-right: 1px solid #2a2e38;
    /* Borde derecho entre elementos */
}

.mobile-menu a span {
    font-size: 18px;
    /* Ajuste del tamaño de la fuente del texto */
}

.mobile-menu a.active {
    color: #ff69c4;
    /* Color activo para el enlace */
}

.mobile-menu a .icon {
    font-size: 40px;
    /* Ajuste del tamaño del icono */
}

.item-li {
    width: 100%;
    height: 3rem;
    display: flex;
    align-items: center;
    transition: color .2s ease-in-out;
    animation: fadeIn 1.5s ease-in-out;
    font-weight: 400;


}

.contenedor-directos-cat {
    animation: fadeIn 1.5s ease-in-out;

}

/* Animación de entrada */
@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.pixel-li {
    padding: 1rem;
    border: 1px solid #2a2e38;
    border-right: none;
    border-left: none;
    border-top: none;
    width: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
    animation: fadeIn 1.5s ease-in-out;

}

.pixel-li:hover::after {
    background-color: #ff69c4;
    position: absolute;
    content: '';
    height: 3rem;
    width: .25rem;
    opacity: 1;
    border-radius: 0 1.5rem 1.5rem 0;
    transition: opacity 0.3s ease;
    /* Transición suave */
    left: 0;
}

.item-li:hover {
    color: #ff69c4 !important;
    cursor: pointer;
}

.item-li::after {
    background-color: #ff69c4;
    position: absolute;
    content: '';
    height: 3rem;
    width: .25rem;
    opacity: 0;
    border-radius: 0 1.5rem 1.5rem 0;
    transition: opacity 0.3s ease;
    /* Transición suave */
    left: 0;
    top: 0;
}

.item-liActive {
    width: 100%;
    height: 3rem;
    display: flex;
    align-items: center;
    transition: color .2s ease-in-out;
    animation: fadeIn 1.5s ease-in-out;
    color: #ff69c4 !important;
    cursor: pointer;
    font-weight: 400;

}

.item-liActiveMobile {
    color: #ff69c4 !important;
}

.item-liActive::after {
    background-color: #ff69c4;
    position: absolute;
    content: '';
    height: 3rem;
    width: .25rem;
    opacity: 1;
    border-radius: 0 1.5rem 1.5rem 0;
    transition: opacity 0.3s ease;
    /* Transición suave */
    left: 0;
    top: 0;
}

.expandedCartera {
    animation: bounce .1s both;
}

.moneda:hover {
    background-color: #2a2e38;
    cursor: pointer;
}

@keyframes bounce {

    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0);
    }

    40% {
        transform: translateY(-5px);
    }

    60% {
        transform: translateY(-5px);
    }
}

.item-li:hover::after {
    opacity: 1;
    /* Mostrar el contenido al hacer hover */
}

.img-bars {
    /* transition: all .3s ease-in-out; */
}

.img-bars:hover {
    fill: #ff69c4 !important;
    cursor: pointer;
}


.button-sports:hover {
    background: url("/public/images/BOTON-DIRECTO-blanco-y-negro.jpg") #2a2e38;
    cursor: pointer;
}

.button-casino:hover {
    background: url("/public/images/BOTON-DIRECTO.jpg") !important;
    background-size: cover !important;

    cursor: pointer;
}

.messagechat-InfoUserTo-notiNav {
    display: flex;
    position: absolute;
    top: 1px;
    left: 15px;
    width: 15px;
    height: 15px;
    background-color: #ff00ea59;
    border-radius: 50%;
    margin-top: 2px;
    margin-left: 5px;
    margin-right: 7px;
    animation: shadowPulseAnimationNoti 1s infinite ease-out;
    align-items: center;
    font-size: 12px;
    justify-content: center;
    z-index: 10;
    /* font-weight: 700; */
    color: #d3c9cc;
}

@keyframes shadowPulseAnimationNoti {
    0% {
        box-shadow: 0 0 00e70166;
    }

    30% {
        box-shadow: 0 0 2px 2px #ff00ea59
    }

    70% {
        box-shadow: 0 0 2px 2px #ff00ea59;
    }
}

@media screen and (max-width: 768px) {

    .openNotificationopenMessage {
        width: 100%;
        border: 1px solid rgb(42, 46, 56);
        background-color: #080808;
        position: fixed;
        z-index: 10000;
        
        transition: left 0.5s ease
    }
}